<template>
  <footer><h1>footer</h1></footer>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import type { ComponentData } from '~/types/index';

export default defineNuxtComponent({
  name: 'Theme7BaseFooterOne',

  mixins: [ComponentMixin],

  props: {
    componentData: {
      type: Object as PropType<ComponentData>,
      required: true,
    },
  },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {},
});
</script>

<style scoped>
a:hover svg path {
  fill: white;
}
</style>
