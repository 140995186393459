<template>
  <header>
    <h1>header</h1>
  </header>
</template>

<script lang="ts">
import { ViewTypes } from '~/constants/ui.constants';
import { ComponentMixin } from '~/mixins/component.mixin';

type Menu = {};

export default defineNuxtComponent({
  name: 'Theme7BaseHeaderOne',

  mixins: [ComponentMixin],

  props: {
    component: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      ViewTypes,
      menuVisible: false,
      isScrolled: false,
      openSubMenu: null,
      variants: {
        HEADER_1: 'HEADER_1',
        HEADER_2: 'HEADER_2',
      },
      isActive: false,
      scrollThreshold: 350,
      isLightMode: false,
      isMenuOpen: false,
      scrollPosition: 0,
    };
  },

  computed: {},

  methods: {},
});
</script>
<style>
.no-scroll {
  overflow: hidden;
  width: 100%;
}
</style>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
</style>
