<template>
  <h1>Blog Detail</h1>
</template>

<script lang="ts">
import type { Component } from '~/types';
import { ComponentMixin } from '~/mixins/component.mixin';
import { BlogMixin } from '~/units/blogs/mixins/blog.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import { ROUTES } from '~/constants/ui.constants';
import AvailableComponentsForSidebar from '~/components/theme6/AvailableComponentsForSidebar.vue';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'Theme7BlogDetail',

  mixins: [ComponentMixin, BlogMixin],

  components: { AvailableComponentsForSidebar },

  data() {
    return { form: null };
  },

  created() {
    this.form = this.parseFormV2(this.vars.blogForm);
  },

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchSingleBlog(props.component);
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),

    ROUTES() {
      return ROUTES;
    },
  },
});
</script>

<style scoped>
.blog-detail-content ::v-deep(*) {
  color: var(--c-text-primary) !important;
}

.blog-detail-content ::v-deep(a) {
  padding: 0 !important;
}

.blog-detail-content ::v-deep(iframe) {
  max-width: 100%;
}
</style>
