<template>
  <h1>Latest Properties</h1>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertiesMixin } from '~/units/properties/mixins/properties.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

interface ComponentData {
  swiper: any | null;
  canPaginateBack: boolean;
  selectedProperty: any | null;
  isVisible: boolean;
  canPaginateNext: boolean;
  variants: {
    v1: string;
    v2: string;
  };
}

export default defineNuxtComponent({
  name: 'Theme7LatestProperties',

  mixins: [ComponentMixin, PropertiesMixin],

  async setup(props: any) {
    try {
      await fetchPropertiesManager(props.component, null);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  },

  data(): ComponentData {
    return {
      swiper: null,
      canPaginateBack: true,
      selectedProperty: null,
      isVisible: false,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  mounted() {
    try {
      this.onSlideChange();
    } catch (error) {
      console.error('Error in mounted hook:', error);
    }
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
    loopedProperties() {
      try {
        return this.properties ? [...this.properties, ...this.properties] : [];
      } catch (error) {
        console.error('Error in loopedProperties computed:', error);
        return [];
      }
    },
  },

  methods: {
    onSwiper(swiper: any) {
      try {
        if (swiper) {
          this.swiper = swiper;
        }
      } catch (error) {
        console.error('Error in onSwiper:', error);
      }
    },

    onSlideChange() {
      try {
        if (!this.swiper) return;

        const isAtStart = this.swiper.isBeginning;
        const isAtEnd = this.swiper.isEnd;

        this.canPaginateBack = !isAtStart;
        this.canPaginateNext = !isAtEnd;
      } catch (error) {
        console.error('Error in onSlideChange:', error);
      }
    },
  },
});
</script>

<style scoped>
::v-deep(.v3-left-arrow),
::v-deep(.v3-right-arrow) {
  margin-top: -100px;
}

::v-deep(.property-list-item) {
  min-height: unset !important;
}
.sidebar ::v-deep(.property-list-item) {
  background-color: var(--c-quaternary) !important;
}
</style>
