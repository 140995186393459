<template>
  <h1>Blogs Page</h1>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import type { Component } from '~/types';
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme4/base/NotFound.vue';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import SearchBox from '~/components/common/SearchBox.vue';
import { useWebsiteStore } from '~/units/website/store';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'Theme7BlogsPage',
  components: { SearchBox, NcButton, NotFound, Loader },

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  computed: {
    ...mapState(useWebsiteStore, ['theme', 'sidebarComponents']),

    isLatestCarouselEnabled() {
      return this.vars.isLatestCarouselEnabledNumber;
    },

    isLatestCarouselVisible() {
      const route = useRoute();
      return this.isLatestCarouselEnabled && this.blogs.length !== 0 && !route.query.q && !route.query.page;
    },

    isPaginationEnabled() {
      return this.vars.isPaginationEnabledBool;
    },

    isPaginationVisible() {
      return this.isPaginationEnabled && !this.isBlogsLoading;
    },

    variant() {
      return useVariant(this.component);
    },

    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },

    titleHtml() {
      return this.vars.titleHtml;
    },

    ROUTES() {
      return ROUTES;
    },

    is() {
      return defineAsyncComponent(
        /* TODO: ~ didn't work when I used it. */
        () => import(`../AvailableComponentsForSidebar.vue`),
      );
    },
  },

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  data() {
    return {
      variants: {
        v1: 'v1',
      },
      keyword: '',
      searchBoxPlaceholder: 'Search',
    };
  },

  mounted() {
    const route = useRoute();
    // TODO: Check where we are in blogs list or not
    if (route.query.q) {
      this.keyword = route.query.q as string;
    }
  },

  methods: {
    async onSearched() {
      const blogsStore = useBlogsStore();
      await blogsStore.searchBlogs(this.keyword);
    },
  },

  watch: {
    async $route(a, b) {
      if (a.query.q !== b.query.q) {
        await fetchBlogsManager(this.component, a.query && a.query.q ? a.query.q : null);
      }
    },
  },
});
</script>
