<template>
  <!--
  <div class="preloader js-preloader">
    <div class="preloader__wrap">
      <div class="preloader__icon">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_729_2673)">
            <path
              d="M12.8442 10.5589L15.9802 9.39139L16.0126 31.9993C17.0681 31.9994 18.1209 31.8957 19.156 31.6895V10.56L15.999 8.7373L12.8442 10.5589Z"
              fill="#C29F7B"
            />
            <path
              d="M27.3139 4.68612C24.2917 1.66467 20.274 0 16 0C11.726 0 7.70831 1.66467 4.68612 4.68612C1.66394 7.70758 0 11.7264 0 16C0 20.2736 1.66467 24.2917 4.68612 27.3139C6.11337 28.7454 7.7991 29.8934 9.65399 30.697C10.667 31.1348 11.7232 31.4651 12.8051 31.6825V15.1386L9.6492 13.3159L6.49332 15.1367L9.63005 13.9696L9.6492 27.5326C5.5877 25.2861 2.83178 20.9598 2.83178 16C2.83178 8.73916 8.73916 2.83178 16 2.83178C23.2608 2.83178 29.1686 8.73916 29.1686 16C29.1708 19.3934 27.8585 22.6557 25.507 25.1023V15.1386L22.3501 13.3159L19.1949 15.1367L22.3309 13.9696L22.3552 30.6937C23.472 30.2099 24.5294 29.5993 25.5067 28.874C26.1465 28.3988 26.7505 27.8772 27.3139 27.3135C30.3361 24.2913 32 20.2736 32 15.9996C32 11.7256 30.3361 7.70831 27.3139 4.68612Z"
              fill="#C29F7B"
            />
          </g>
          <defs>
            <clipPath id="clip0_729_2673">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>

    <div class="preloader__title">Nerf</div>
  </div>

  <div class="cursor js-cursor">
    <div class="cursor__wrapper">
      <div class="cursor__follower js-follower"></div>
      <div class="cursor__label js-label"></div>
      <div class="cursor__icon js-icon"></div>
    </div>
  </div>

  <button class="toTopButton js-top-button">
    <i class="icon-down-arrow"></i>
  </button>

  <main>
    <header class="header -type-5 js-header">
      <div data-anim="fade delay-3" class="header__container">
        <div class="header__left">
          <div class="headerMenu">
            <div class="headerMenu__item">
              <a href="#">HOME</a>

              <div class="headerMenuSubnav">
                <div class="headerMenuSubnav__item">
                  <a href="index.html">Home 1</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="home-2.html">Home 2</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="home-3.html">Home 3</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="home-4.html">Home 4</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="home-5.html">Home 5</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="home-6.html">Home 6</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="home-7.html">Home 7</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="home-8.html">Home 8</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="home-9.html">Home 9</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="home-10.html">Home 10</a>
                </div>
              </div>
            </div>

            <div class="headerMenu__item">
              <a href="#">PAGES</a>

              <div class="headerMenuSubnav">
                <div class="headerMenuSubnav__item">
                  <a href="about.html">About</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="contact.html">Contact</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="services.html">SERVICES</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="neighborhood.html">neighborhood</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="agents.html">AGENTS</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="gallery.html">GALLERY</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="faq.html">FAQ</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="amenities.html">AMENITIES</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="404.html">404 PAGE</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="terms.html">terms</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="ui-elements.html">UI ELEMENTS</a>
                </div>
              </div>
            </div>

            <div class="headerMenu__item">
              <a href="#">PROPERTY</a>

              <div class="headerMenuSubnav">
                <div class="headerMenuSubnav__item">
                  <a href="property-list-1.html">Property list 1</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="property-list-2.html">Property list 2</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="property-list-3.html">Property list 3</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="property-single-1.html">Property single 1</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="property-single-2.html">Property single 2</a>
                </div>
              </div>
            </div>

            <div class="headerMenu__item">
              <a href="#">APARTMENT</a>

              <div class="headerMenuSubnav">
                <div class="headerMenuSubnav__item">
                  <a href="apartments-list-1.html">Apartment list 1</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="apartments-list-2.html">Apartment list 2</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="apartments-list-3.html">Apartment list 3</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="apartments-single-1.html">Apartment single 1</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="apartments-single-2.html">Apartment single 2</a>
                </div>
              </div>
            </div>

            <div class="headerMenu__item">
              <a href="#">BLOG</a>

              <div class="headerMenuSubnav">
                <div class="headerMenuSubnav__item">
                  <a href="blog-list-1.html">Blog list 1</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="blog-list-2.html">Blog list 2</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="blog-list-3.html">Blog list 3</a>
                </div>

                <div class="headerMenuSubnav__item">
                  <a href="blog-single.html">Blog single</a>
                </div>
              </div>
            </div>

            <div class="headerMenu__item">
              <a href="contact.html">CONTACT US</a>
            </div>
          </div>
        </div>

        <div class="header__center">
          <a href="index.html" class="header__logo">
            <img src="/public/theme7/img/general/logo.svg" alt="logo icon" />
            <img src="/public/theme7/img/general/logo-dark.svg" alt="logo icon" />
          </a>
        </div>

        <div class="header__right">
          <div class="header__number xl:d-none">
            <a href="#">+3 968 318 70 00</a>
          </div>

          <button class="header__menuBtn js-menu-button">
            <i></i>
            <span>MENU</span>
          </button>

          <button class="button -md -outline-white text-white lg:d-none js-toggle-requestForm">
            Request a viewing
          </button>
        </div>
      </div>
    </header>

    <div class="menu -top-header js-menu">
      <div class="menu__left">
        <div class="menuNav js-menuNav">
          <div class="menuNav__item -has-submenu js-has-submenu">
            <a href="#">
              HOME
              <i class="icon-plus"></i>
            </a>

            <div class="submenu js-submenu">
              <div class="submenu__wrap">
                <div class="submenu__item">
                  <a href="index.html">Home 1</a>
                </div>

                <div class="submenu__item">
                  <a href="home-2.html">Home 2</a>
                </div>

                <div class="submenu__item">
                  <a href="home-3.html">Home 3</a>
                </div>

                <div class="submenu__item">
                  <a href="home-4.html">Home 4</a>
                </div>

                <div class="submenu__item">
                  <a href="home-5.html">Home 5</a>
                </div>

                <div class="submenu__item">
                  <a href="home-6.html">Home 6</a>
                </div>

                <div class="submenu__item">
                  <a href="home-7.html">Home 7</a>
                </div>

                <div class="submenu__item">
                  <a href="home-8.html">Home 8</a>
                </div>

                <div class="submenu__item">
                  <a href="home-9.html">Home 9</a>
                </div>

                <div class="submenu__item">
                  <a href="home-10.html">Home 10</a>
                </div>
              </div>
            </div>
          </div>

          <div class="menuNav__item -has-submenu js-has-submenu">
            <a href="#">
              PAGES
              <i class="icon-plus"></i>
            </a>

            <div class="submenu js-submenu">
              <div class="submenu__wrap">
                <div class="submenu__item">
                  <a href="about.html">About</a>
                </div>

                <div class="submenu__item">
                  <a href="contact.html">Contact</a>
                </div>

                <div class="submenu__item">
                  <a href="services.html">SERVICES</a>
                </div>

                <div class="submenu__item">
                  <a href="neighborhood.html">neighborhood</a>
                </div>

                <div class="submenu__item">
                  <a href="agents.html">AGENTS</a>
                </div>

                <div class="submenu__item">
                  <a href="gallery.html">GALLERY</a>
                </div>

                <div class="submenu__item">
                  <a href="faq.html">FAQ</a>
                </div>

                <div class="submenu__item">
                  <a href="amenities.html">AMENITIES</a>
                </div>

                <div class="submenu__item">
                  <a href="404.html">404 PAGE</a>
                </div>

                <div class="submenu__item">
                  <a href="terms.html">terms</a>
                </div>

                <div class="submenu__item">
                  <a href="ui-elements.html">UI ELEMENTS</a>
                </div>
              </div>
            </div>
          </div>

          <div class="menuNav__item -has-submenu js-has-submenu">
            <a href="#">
              PROPERTY
              <i class="icon-plus"></i>
            </a>

            <div class="submenu js-submenu">
              <div class="submenu__wrap">
                <div class="submenu__item">
                  <a href="property-list-1.html">Property list 1</a>
                </div>

                <div class="submenu__item">
                  <a href="property-list-2.html">Property list 2</a>
                </div>

                <div class="submenu__item">
                  <a href="property-list-3.html">Property list 3</a>
                </div>

                <div class="submenu__item">
                  <a href="property-single-1.html">Property single 1</a>
                </div>

                <div class="submenu__item">
                  <a href="property-single-2.html">Property single 2</a>
                </div>
              </div>
            </div>
          </div>

          <div class="menuNav__item -has-submenu js-has-submenu">
            <a href="#">
              APARTMENT
              <i class="icon-plus"></i>
            </a>

            <div class="submenu js-submenu">
              <div class="submenu__wrap">
                <div class="submenu__item">
                  <a href="apartments-list-1.html">Apartment list 1</a>
                </div>

                <div class="submenu__item">
                  <a href="apartments-list-2.html">Apartment list 2</a>
                </div>

                <div class="submenu__item">
                  <a href="apartments-list-3.html">Apartment list 3</a>
                </div>

                <div class="submenu__item">
                  <a href="apartments-single-1.html">Apartment single 1</a>
                </div>

                <div class="submenu__item">
                  <a href="apartments-single-2.html">Apartment single 2</a>
                </div>
              </div>
            </div>
          </div>

          <div class="menuNav__item -has-submenu js-has-submenu">
            <a href="#">
              BLOG
              <i class="icon-plus"></i>
            </a>

            <div class="submenu js-submenu">
              <div class="submenu__wrap">
                <div class="submenu__item">
                  <a href="blog-list-1.html">Blog list 1</a>
                </div>

                <div class="submenu__item">
                  <a href="blog-list-2.html">Blog list 2</a>
                </div>

                <div class="submenu__item">
                  <a href="blog-list-3.html">Blog list 3</a>
                </div>

                <div class="submenu__item">
                  <a href="blog-single.html">Blog single</a>
                </div>
              </div>
            </div>
          </div>

          <div class="menuNav__item">
            <a href="contact.html">CONTACT US</a>
          </div>
        </div>
      </div>

      <div class="menu__right">
        <div class="menuImage js-menuImage">
          <img src="/public/theme7/img/menu/1.png" alt="image" />
        </div>
      </div>

      <div class="menu__close js-menuClose js-menu-button">
        <i class="icon-cross"></i>
      </div>
    </div>

    <section data-anim-wrap class="hero -type-2 js-hero-type-2">
      <div data-anim-child="fade delay-1" class="hero__bg" data-parallax="0.7">
        <img data-parallax-target src="/public/theme7/img/hero/hero-2/1.png" alt="image" />
      </div>

      <div data-anim-child="slide-up delay-4" class="container">
        <div class="hero__content">
          <h1 class="hero__title">
            Innovation &
            <span class="text-accent-1">elegance</span>
          </h1>
        </div>
      </div>

      <div data-anim-child="fade delay-6">
        <button class="hero__buttonDown">
          <i class="icon-right-arrow"></i>
        </button>
      </div>
    </section>

    <div class="pt-100 md:pt-20 sm:pt-60 bg-dark-2"></div>

    <section class="layout-pt-lg layout-pb-lg relative bg-dark-2">
      <div class="container">
        <div class="shapeBg -type-1">
          <svg width="815" height="1150" viewBox="0 0 815 1150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.02">
              <g clip-path="url(#clip0_125_814)">
                <path
                  d="M421.054 1090.59H422.842H421.054ZM422.842 1090.59L430.63 1090.2H431.078H431.595H432.536L435.101 1089.99V313.909L379.899 363.996V1089.75L382.464 1089.96H383.405H383.899H384.37L392.158 1090.36H394.888L379.899 1089.54L422.842 1090.59ZM295.778 30.5736L475.315 193.385V223.656L552.965 153.334V1061.3C614.645 1034.95 667.845 992.379 706.78 938.203C745.715 884.027 768.894 820.323 773.797 754.021C778.7 687.718 765.139 621.355 734.588 562.147C704.038 502.939 657.668 453.153 600.52 418.204V372.216C669.267 408.827 725.943 464.204 763.812 531.763C801.681 599.322 819.15 676.221 814.141 753.318C809.132 830.415 781.857 904.467 735.558 966.671C689.259 1028.88 625.883 1076.61 552.965 1104.21V1104.45L545.294 1107.05C534.612 1110.84 523.748 1114.19 512.705 1117.11V243.611L475.315 277.421V1125.5L460.514 1127.27L459.338 1127.43L457.549 1127.61C445.502 1129.17 433.313 1130.11 420.983 1130.43H418.63H418.371H416.606H414.253H400.559H398.206H396.558H395.97H393.947L390.488 1130.32H388.77L385.311 1130.18H385.052H384.181H383.546H382.393H381.805C373.663 1129.69 365.545 1128.92 357.545 1127.85L355.757 1127.66L354.039 1127.45L339.78 1125.73V346.718L346.368 340.734L435.219 260.144V210.662L302.413 90.3005V1117.18C291.495 1114.32 280.765 1110.99 270.247 1107.26L262.153 1104.52V1104.31C189.239 1076.7 125.867 1028.95 79.5668 966.751C33.2664 904.548 5.98406 830.5 0.961196 753.404C-4.06166 676.308 13.3862 599.405 51.2316 531.834C89.077 464.262 145.729 408.862 214.456 372.216V418.204C157.306 453.156 110.936 502.948 80.39 562.163C49.8444 621.379 36.2934 687.749 41.2113 754.055C46.1292 820.361 69.3275 884.062 108.283 938.229C147.238 992.397 200.458 1034.96 262.153 1061.28V0L295.778 30.5736Z"
                  fill="white"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_125_814">
                <rect width="815" height="1130.67" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div class="row justify-end">
          <div class="col-xl-5">
            <p class="text-20 text-accent-1">
              LUXURY FAMILY RESIDENCES<br />
              WITH A WELL-DEVELOPED<br />
              INFRASTRUCTURE WITHIN A MINUTE<br />
              OF <span class="text-white">NERF</span>
            </p>

            <p class="text-18 md:text-16 text-white mt-60 md:mt-20">
              Each apartment has been individually designed to maximise space and light. Smart Home Technology installed
              as standard in each apartment putting you in total control of your home at the touch of a button from
              wherever you might be.
            </p>
          </div>
        </div>

        <div class="row y-gap-30 justify-end pt-60 md:pt-30">
          <div class="col-md-3 col-6">
            <div class="text-center">
              <div class="text-62 lg:text-40 fw-500 lh-14 text-white">684</div>
              <div class="text-18 lg:text-16 fw-500 lh-14 text-white">SQUARE AREAS</div>
            </div>
          </div>

          <div class="col-md-3 col-6">
            <div class="text-center">
              <div class="text-62 lg:text-40 fw-500 lh-14 text-white">07</div>
              <div class="text-18 lg:text-16 fw-500 lh-14 text-white">CAR PARKING</div>
            </div>
          </div>

          <div class="col-md-3 col-6">
            <div class="text-center">
              <div class="text-62 lg:text-40 fw-500 lh-14 text-white">34</div>
              <div class="text-18 lg:text-16 fw-500 lh-14 text-white">APARTMENTS</div>
            </div>
          </div>

          <div class="col-md-3 col-6">
            <div class="text-center">
              <div class="text-62 lg:text-40 fw-500 lh-14 text-white">12</div>
              <div class="text-18 lg:text-16 fw-500 lh-14 text-white">ROOMS</div>
            </div>
          </div>
        </div>

        <div
          class="relative pt-60 md:pt-40 sm:pt-20 js-section-slider"
          data-gap="30"
          data-slider-cols="xl-2 lg-2 md-2 sm-1 base-1"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="ratio ratio-69:65">
                <img src="/public/theme7/img/imageSlider/5/1.png" alt="image" class="img-ratio" />
              </div>
            </div>

            <div class="swiper-slide">
              <div class="ratio ratio-69:65">
                <img src="/public/theme7/img/imageSlider/5/2.png" alt="image" class="img-ratio" />
              </div>
            </div>

            <div class="swiper-slide">
              <div class="ratio ratio-69:65">
                <img src="/public/theme7/img/imageSlider/5/3.png" alt="image" class="img-ratio" />
              </div>
            </div>
          </div>

          <div class="d-flex mt-60 md:mt-30">
            <button class="sliderNav -prev -transparent text-white js-prev">
              <i class="icon-left-arrow"></i>
            </button>
            <button class="sliderNav -next -transparent text-white ml-20 js-next">
              <i class="icon-right-arrow"></i>
            </button>
          </div>
        </div>
      </div>
    </section>

    <section data-parallax="0.7" class="videoSection">
      <div data-parallax-target>
        <img src="/public/theme7/img/video/1.png" alt="image" />
      </div>

      <a
        href="https://www.youtube.com/watch?v=ANYfx4-jyqY"
        class="play-button border-white text-white js-gallery"
        data-gallery="gallery1"
      >
        <i class="icon-play text-30"></i>
      </a>
    </section>

    <section class="layout-pt-lg layout-pb-lg bg-dark-2">
      <div class="container">
        <div class="row justify-center text-center">
          <div class="col-auto">
            <h2 class="text-62 md:text-38 uppercase fw-500 text-white">
              <span class="text-accent-1">BUILDING</span> AMENITIES
            </h2>
          </div>
        </div>

        <div class="row y-gap-60 mt-60 md:mt-40 sm:mt-20">
          <div class="col-xl-4 col-md-6">
            <a href="#" class="iconCard -type-2 text-white">
              <div class="iconCard__content text-white">
                <i class="iconCard__icon icon-customer-support"></i>

                <h3 class="iconCard__title">24 HOUR CONCIERGE</h3>

                <P class="iconCard__text">
                  Laborum magna nulla duis ullamco <br />
                  cillum dolor.
                </P>
              </div>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="iconCard -type-2 text-white">
              <div class="iconCard__content text-white">
                <i class="iconCard__icon icon-bike"></i>

                <h3 class="iconCard__title">BIKE STORAGE</h3>

                <P class="iconCard__text">
                  Laborum magna nulla duis ullamco <br />
                  cillum dolor.
                </P>
              </div>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="iconCard -type-2 text-white">
              <div class="iconCard__content text-white">
                <i class="iconCard__icon icon-swimming-pool"></i>

                <h3 class="iconCard__title">SWIMMING POOL</h3>

                <P class="iconCard__text">
                  Laborum magna nulla duis ullamco <br />
                  cillum dolor.
                </P>
              </div>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="iconCard -type-2 text-white">
              <div class="iconCard__content text-white">
                <i class="iconCard__icon icon-sofa"></i>

                <h3 class="iconCard__title">LOUNGE</h3>

                <P class="iconCard__text">
                  Laborum magna nulla duis ullamco <br />
                  cillum dolor.
                </P>
              </div>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="iconCard -type-2 text-white">
              <div class="iconCard__content text-white">
                <i class="iconCard__icon icon-pawprint"></i>

                <h3 class="iconCard__title">PETS ALLOWED</h3>

                <P class="iconCard__text">
                  Laborum magna nulla duis ullamco <br />
                  cillum dolor.
                </P>
              </div>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="iconCard -type-2 text-white">
              <div class="iconCard__content text-white">
                <i class="iconCard__icon icon-gym"></i>

                <h3 class="iconCard__title">GYM &amp; FITNESS</h3>

                <P class="iconCard__text">
                  Laborum magna nulla duis ullamco <br />
                  cillum dolor.
                </P>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="layout-pt-lg layout-pb-lg bg-accent-2">
      <div class="container">
        <div class="row justify-between items-center">
          <div class="col-auto">
            <h2 class="text-62 md:text-38 uppercase fw-500">
              <span class="text-accent-1">CHOOSE AN</span><br />
              APARTMENT
            </h2>
          </div>

          <div class="col-xl-6">
            <p class="">
              From the vibrant ground level plaza to the terraces, privategardens, balconies and lush rooftop work
              spaces, Costix offersan array of amenities …
            </p>
          </div>
        </div>

        <div class="row y-gap-30 mt-60 md:mt-40 sm:mt-20">
          <div class="col-xl-4 col-md-6">
            <a href="#" class="imageCard -type-2 hover-btn-explore">
              <div class="imageCard__image relative ratio ratio-45:40">
                <img src="/public/theme7/img/imageSlider/2/1.png" alt="image" class="img-ratio" />

                <div class="hover-btn-explore__item">
                  <button class="button text-white bg-dark-1 size-120 fw-500 rounded-full">Explore</button>
                </div>
              </div>

              <h3 class="imageCard__title text-dark-1">STUDIO APARTMENT</h3>
              <p class="imageCard__text text-dark-1">FROM 120 M2</p>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="imageCard -type-2 hover-btn-explore">
              <div class="imageCard__image relative ratio ratio-45:40">
                <img src="/public/theme7/img/imageSlider/2/2.png" alt="image" class="img-ratio" />

                <div class="hover-btn-explore__item">
                  <button class="button text-white bg-dark-1 size-120 fw-500 rounded-full">Explore</button>
                </div>
              </div>

              <h3 class="imageCard__title text-dark-1">LUXURY SUITE</h3>
              <p class="imageCard__text text-dark-1">FROM 120 M2</p>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="imageCard -type-2 hover-btn-explore">
              <div class="imageCard__image relative ratio ratio-45:40">
                <img src="/public/theme7/img/imageSlider/2/3.png" alt="image" class="img-ratio" />

                <div class="hover-btn-explore__item">
                  <button class="button text-white bg-dark-1 size-120 fw-500 rounded-full">Explore</button>
                </div>
              </div>

              <h3 class="imageCard__title text-dark-1">MODERN STUDIO</h3>
              <p class="imageCard__text text-dark-1">FROM 120 M2</p>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="imageCard -type-2 hover-btn-explore">
              <div class="imageCard__image relative ratio ratio-45:40">
                <img src="/public/theme7/img/imageSlider/2/4.png" alt="image" class="img-ratio" />

                <div class="hover-btn-explore__item">
                  <button class="button text-white bg-dark-1 size-120 fw-500 rounded-full">Explore</button>
                </div>
              </div>

              <h3 class="imageCard__title text-dark-1">NORTH DUPLEX</h3>
              <p class="imageCard__text text-dark-1">FROM 120 M2</p>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="imageCard -type-2 hover-btn-explore">
              <div class="imageCard__image relative ratio ratio-45:40">
                <img src="/public/theme7/img/imageSlider/2/5.png" alt="image" class="img-ratio" />

                <div class="hover-btn-explore__item">
                  <button class="button text-white bg-dark-1 size-120 fw-500 rounded-full">Explore</button>
                </div>
              </div>

              <h3 class="imageCard__title text-dark-1">STUDIO APARTMENT</h3>
              <p class="imageCard__text text-dark-1">FROM 120 M2</p>
            </a>
          </div>

          <div class="col-xl-4 col-md-6">
            <a href="#" class="imageCard -type-2 hover-btn-explore">
              <div class="imageCard__image relative ratio ratio-45:40">
                <img src="/public/theme7/img/imageSlider/2/6.png" alt="image" class="img-ratio" />

                <div class="hover-btn-explore__item">
                  <button class="button text-white bg-dark-1 size-120 fw-500 rounded-full">Explore</button>
                </div>
              </div>

              <h3 class="imageCard__title text-dark-1">LUXURY SUITE</h3>
              <p class="imageCard__text text-dark-1">FROM 120 M2</p>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="layout-pt-lg layout-pb-lg bg-accent-1">
      <div class="container">
        <div class="row justify-center text-center">
          <div class="col-auto">
            <h2 class="text-62 md:text-38 uppercase fw-500 text-white">SCHEDULE A VISIT</h2>
            <p class="text-white mt-10">
              At egestas pulvinar faucibus sit ullamcorper venenatis eget.<br />
              Vulputate magna nullam et aliquam fermentum vitae.
            </p>

            <div class="d-flex justify-center">
              <button class="button -md -outline-white text-white mt-30">REQUEST A VIEWING</button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="layout-pt-lg layout-pb-lg bg-dark-2">
      <div class="container">
        <div class="row justify-between items-center">
          <div class="col-auto">
            <h2 class="text-62 md:text-38 uppercase fw-500 text-white">
              <span class="text-accent-1">Apartment</span><br />
              Neighborhoods
            </h2>
          </div>

          <div class="col-xl-6">
            <p class="text-white">
              From the vibrant ground level plaza to the terraces, privategardens, balconies and lush rooftop work
              spaces, Costix offersan array of amenities …
            </p>
          </div>
        </div>

        <div class="mapPlaces pt-60 md:pt-60 js-mapPlaces">
          <div class="row items-center">
            <div class="col-lg-5">
              <div class="y-gap-30">
                <div class="mapPlacesCard isCardActive" data-map-card="volta">
                  <div>
                    <h4 class="mapPlacesCard__title text-white">VOLTA MALLS</h4>
                    <div class="mapPlacesCard__caption text-white">2 KM FAR - SHOPPING MALLS</div>
                  </div>

                  <img src="/public/theme7/img/tabs/1/1.png" alt="image" class="mapPlacesCard__image" />
                </div>

                <div class="mapPlacesCard isCardActive" data-map-card="bistro">
                  <div>
                    <h4 class="mapPlacesCard__title text-white">CAFE BISTRO</h4>
                    <div class="mapPlacesCard__caption text-white">2 KM FAR - RESTAURANT</div>
                  </div>

                  <img src="/public/theme7/img/tabs/1/2.png" alt="image" class="mapPlacesCard__image" />
                </div>

                <div class="mapPlacesCard isCardActive" data-map-card="clinic">
                  <div>
                    <h4 class="mapPlacesCard__title text-white">NY CLINIC</h4>
                    <div class="mapPlacesCard__caption text-white">2 KM FAR - HOSPITAL</div>
                  </div>

                  <img src="/public/theme7/img/tabs/1/3.png" alt="image" class="mapPlacesCard__image" />
                </div>

                <div class="mapPlacesCard isCardActive" data-map-card="edumy">
                  <div>
                    <h4 class="mapPlacesCard__title text-white">EDUMY COLLEGE</h4>
                    <div class="mapPlacesCard__caption text-white">2 KM FAR - SCHOOL</div>
                  </div>

                  <img src="/public/theme7/img/tabs/1/4.png" alt="image" class="mapPlacesCard__image" />
                </div>
              </div>
            </div>

            <div class="col-lg-7">
              <div class="mapPlaces__map">
                <img src="/public/theme7/img/tabs/2/map.png" alt="map" />

                <div class="mapPlacesPlace" data-map-place="volta">
                  <div class="mapPlacesPlace__content">VOLTA MALLS</div>
                  <div class="mapPlacesPlace__marker"></div>
                </div>

                <div class="mapPlacesPlace" data-map-place="bistro">
                  <div class="mapPlacesPlace__content">CAFE BISTRO</div>
                  <div class="mapPlacesPlace__marker"></div>
                </div>

                <div class="mapPlacesPlace" data-map-place="clinic">
                  <div class="mapPlacesPlace__content">NY CLINIC</div>
                  <div class="mapPlacesPlace__marker"></div>
                </div>

                <div class="mapPlacesPlace" data-map-place="edumy">
                  <div class="mapPlacesPlace__content">EDUMY COLLEGE</div>
                  <div class="mapPlacesPlace__marker"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="layout-pt-lg layout-pb-lg">
      <div data-anim-wrap class="container">
        <div class="tabs -apartments js-tabs">
          <div data-anim="slide-up delay-1" class="row y-gap-30 justify-between items-center">
            <div class="col-auto">
              <h2 class="text-62 md:text-38 uppercase fw-500">
                <span class="text-accent-1">Apartment</span>
                <br />
                Plans
              </h2>
            </div>

            <div class="col-auto">
              <div class="tabs__controls d-flex x-gap-10 -mobileScroll js-tabs-controls">
                <button
                  class="tabs__button button -md fw-500 js-tabs-button is-tab-el-active"
                  data-tab-target=".-tab-item-1"
                >
                  APARTMENT
                </button>

                <button class="tabs__button button -md fw-500 js-tabs-button" data-tab-target=".-tab-item-2">
                  STUDIO
                </button>

                <button class="tabs__button button -md fw-500 js-tabs-button" data-tab-target=".-tab-item-3">
                  SIMPLEX
                </button>

                <button class="tabs__button button -md fw-500 js-tabs-button" data-tab-target=".-tab-item-4">
                  SUITE
                </button>

                <button class="tabs__button button -md fw-500 js-tabs-button" data-tab-target=".-tab-item-5">
                  DUPLEX
                </button>
              </div>
            </div>
          </div>

          <div data-anim="slide-up delay-2" class="tabs__content pt-60 md:pt-30 js-tabs-content">
            <div class="tabs__pane -tab-item-1 is-tab-el-active">
              <div class="row justify-between">
                <div class="col-xl-7">
                  <img src="/public/theme7/img/plans/1.png" alt="image" />
                </div>

                <div class="col-xl-4">
                  <p class="mb-60 md:mb-30 md:mt-30">
                    From the vibrant ground level plaza to the terraces, privategardens, balconies and lush rooftop work
                    spaces, Costix offersan array of amenities …
                  </p>

                  <div class="">
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">ROOMS</div>
                      <div class="">5</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">FLOOR</div>
                      <div class="">3</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">AREA M2</div>
                      <div class="">125</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">PARKING</div>
                      <div class="">2</div>
                    </div>
                    <div class="d-flex justify-between">
                      <div class="">PRICE</div>
                      <div class="">$7400/M2</div>
                    </div>
                  </div>

                  <button class="button -md -dark-1 bg-accent-1 text-white mt-15 col-12">SCHEDULE</button>
                </div>
              </div>
            </div>

            <div class="tabs__pane -tab-item-2">
              <div class="row justify-between">
                <div class="col-xl-7">
                  <img src="/public/theme7/img/plans/1.png" alt="image" />
                </div>

                <div class="col-xl-4">
                  <p class="mb-60 md:mb-30 md:mt-30">
                    From the vibrant ground level plaza to the terraces, privategardens, balconies and lush rooftop work
                    spaces, Costix offersan array of amenities …
                  </p>

                  <div class="">
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">ROOMS</div>
                      <div class="">5</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">FLOOR</div>
                      <div class="">3</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">AREA M2</div>
                      <div class="">125</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">PARKING</div>
                      <div class="">2</div>
                    </div>
                    <div class="d-flex justify-between">
                      <div class="">PRICE</div>
                      <div class="">$7400/M2</div>
                    </div>
                  </div>

                  <button class="button -md -dark-1 bg-accent-1 text-white mt-15 col-12">SCHEDULE</button>
                </div>
              </div>
            </div>

            <div class="tabs__pane -tab-item-3">
              <div class="row justify-between">
                <div class="col-xl-7">
                  <img src="/public/theme7/img/plans/1.png" alt="image" />
                </div>

                <div class="col-xl-4">
                  <p class="mb-60 md:mb-30 md:mt-30">
                    From the vibrant ground level plaza to the terraces, privategardens, balconies and lush rooftop work
                    spaces, Costix offersan array of amenities …
                  </p>

                  <div class="">
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">ROOMS</div>
                      <div class="">5</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">FLOOR</div>
                      <div class="">3</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">AREA M2</div>
                      <div class="">125</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">PARKING</div>
                      <div class="">2</div>
                    </div>
                    <div class="d-flex justify-between">
                      <div class="">PRICE</div>
                      <div class="">$7400/M2</div>
                    </div>
                  </div>

                  <button class="button -md -dark-1 bg-accent-1 text-white mt-15 col-12">SCHEDULE</button>
                </div>
              </div>
            </div>

            <div class="tabs__pane -tab-item-4">
              <div class="row justify-between">
                <div class="col-xl-7">
                  <img src="/public/theme7/img/plans/1.png" alt="image" />
                </div>

                <div class="col-xl-4">
                  <p class="mb-60 md:mb-30 md:mt-30">
                    From the vibrant ground level plaza to the terraces, privategardens, balconies and lush rooftop work
                    spaces, Costix offersan array of amenities …
                  </p>

                  <div class="">
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">ROOMS</div>
                      <div class="">5</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">FLOOR</div>
                      <div class="">3</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">AREA M2</div>
                      <div class="">125</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">PARKING</div>
                      <div class="">2</div>
                    </div>
                    <div class="d-flex justify-between">
                      <div class="">PRICE</div>
                      <div class="">$7400/M2</div>
                    </div>
                  </div>

                  <button class="button -md -dark-1 bg-accent-1 text-white mt-15 col-12">SCHEDULE</button>
                </div>
              </div>
            </div>

            <div class="tabs__pane -tab-item-5">
              <div class="row justify-between">
                <div class="col-xl-7">
                  <img src="/public/theme7/img/plans/1.png" alt="image" />
                </div>

                <div class="col-xl-4">
                  <p class="mb-60 md:mb-30 md:mt-30">
                    From the vibrant ground level plaza to the terraces, privategardens, balconies and lush rooftop work
                    spaces, Costix offersan array of amenities …
                  </p>

                  <div class="">
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">ROOMS</div>
                      <div class="">5</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">FLOOR</div>
                      <div class="">3</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">AREA M2</div>
                      <div class="">125</div>
                    </div>
                    <div class="d-flex justify-between border-bottom-accent-2 pb-15 mb-15">
                      <div class="">PARKING</div>
                      <div class="">2</div>
                    </div>
                    <div class="d-flex justify-between">
                      <div class="">PRICE</div>
                      <div class="">$7400/M2</div>
                    </div>
                  </div>

                  <button class="button -md -dark-1 bg-accent-1 text-white mt-15 col-12">SCHEDULE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="layout-pt-lg bg-dark-2">
      <div class="container">
        <div class="row justify-center text-center">
          <div class="col-xl-8 col-lg-9 col-md-10">
            <div
              class="overflow-hidden js-section-slider"
              data-gap="30"
              data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
              data-pagination="js-pagination"
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="testimonials -type-1">
                    <div class="testimonials__icon">
                      <svg width="104" height="67" viewBox="0 0 104 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M28.7606 66.2887H29.1469L29.2444 65.9149L46.1458 1.12621L46.3091 0.5H45.662H30.169H29.8412L29.7105 0.800637L1.54146 65.5894L1.23739 66.2887H2H28.7606ZM85.0986 66.2887H85.4849L85.5824 65.9149L102.484 1.12621L102.647 0.5H102H86.507H86.1792L86.0485 0.800637L57.8795 65.5894L57.5754 66.2887H58.338H85.0986Z"
                          stroke="#C29F7B"
                        />
                      </svg>
                    </div>

                    <div class="testimonials__text text-white">
                      Lorem Ipsum roin gravida nibh vel velit auctor aliollicitudin, lorem quis bibendum auctor nisi
                      elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus
                      a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare
                      odio.
                    </div>
                    <div class="testimonials__name text-white">Annette Black</div>
                    <div class="testimonials__position text-white">UX/UI DESINGER - ENVATO</div>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="testimonials -type-1">
                    <div class="testimonials__icon">
                      <svg width="104" height="67" viewBox="0 0 104 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M28.7606 66.2887H29.1469L29.2444 65.9149L46.1458 1.12621L46.3091 0.5H45.662H30.169H29.8412L29.7105 0.800637L1.54146 65.5894L1.23739 66.2887H2H28.7606ZM85.0986 66.2887H85.4849L85.5824 65.9149L102.484 1.12621L102.647 0.5H102H86.507H86.1792L86.0485 0.800637L57.8795 65.5894L57.5754 66.2887H58.338H85.0986Z"
                          stroke="#C29F7B"
                        />
                      </svg>
                    </div>

                    <div class="testimonials__text text-white">
                      Lorem Ipsum roin gravida nibh vel velit auctor aliollicitudin, lorem quis bibendum auctor nisi
                      elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus
                      a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare
                      odio.
                    </div>
                    <div class="testimonials__name text-white">Annette Black</div>
                    <div class="testimonials__position text-white">UX/UI DESINGER - ENVATO</div>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="testimonials -type-1">
                    <div class="testimonials__icon">
                      <svg width="104" height="67" viewBox="0 0 104 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M28.7606 66.2887H29.1469L29.2444 65.9149L46.1458 1.12621L46.3091 0.5H45.662H30.169H29.8412L29.7105 0.800637L1.54146 65.5894L1.23739 66.2887H2H28.7606ZM85.0986 66.2887H85.4849L85.5824 65.9149L102.484 1.12621L102.647 0.5H102H86.507H86.1792L86.0485 0.800637L57.8795 65.5894L57.5754 66.2887H58.338H85.0986Z"
                          stroke="#C29F7B"
                        />
                      </svg>
                    </div>

                    <div class="testimonials__text text-white">
                      Lorem Ipsum roin gravida nibh vel velit auctor aliollicitudin, lorem quis bibendum auctor nisi
                      elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus
                      a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare
                      odio.
                    </div>
                    <div class="testimonials__name text-white">Annette Black</div>
                    <div class="testimonials__position text-white">UX/UI DESINGER - ENVATO</div>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="testimonials -type-1">
                    <div class="testimonials__icon">
                      <svg width="104" height="67" viewBox="0 0 104 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M28.7606 66.2887H29.1469L29.2444 65.9149L46.1458 1.12621L46.3091 0.5H45.662H30.169H29.8412L29.7105 0.800637L1.54146 65.5894L1.23739 66.2887H2H28.7606ZM85.0986 66.2887H85.4849L85.5824 65.9149L102.484 1.12621L102.647 0.5H102H86.507H86.1792L86.0485 0.800637L57.8795 65.5894L57.5754 66.2887H58.338H85.0986Z"
                          stroke="#C29F7B"
                        />
                      </svg>
                    </div>

                    <div class="testimonials__text text-white">
                      Lorem Ipsum roin gravida nibh vel velit auctor aliollicitudin, lorem quis bibendum auctor nisi
                      elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus
                      a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare
                      odio.
                    </div>
                    <div class="testimonials__name text-white">Annette Black</div>
                    <div class="testimonials__position text-white">UX/UI DESINGER - ENVATO</div>
                  </div>
                </div>
              </div>

              <div class="testimonialsNav mt-60 md:mt-30">
                <button class="-prev text-white js-prev">
                  <i class="icon-left-arrow"></i>
                </button>

                <div class="testimonialsPag text-white js-pagination"></div>

                <button class="-next text-white ml-20 js-next">
                  <i class="icon-right-arrow"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="layout-pt-lg layout-pb-lg bg-dark-2">
      <div class="container border-top-dark-5"></div>
    </div>

    <section class="layout-pb-lg bg-dark-2">
      <div class="container">
        <div class="row justify-between items-center">
          <div class="col-auto">
            <h2 class="text-62 md:text-38 uppercase fw-500 text-white">
              <span class="text-accent-1">From our</span>
              <br />
              Blog
            </h2>
          </div>

          <div class="col-xl-6">
            <p class="text-white">
              From the vibrant ground level plaza to the terraces, privategardens, balconies and lush rooftop work
              spaces, Costix offersan array of amenities …
            </p>
          </div>
        </div>

        <div class="row y-gap-30 pt-60 md:pt-30">
          <div class="col-lg-4 col-md-6">
            <a href="blog-single.html" class="blogCard -type-1 -hover-accent">
              <div class="blogCard__content px-40 py-40">
                <div>
                  <div class="blogCard__tag text-accent-1">NEWS</div>
                  <h3 class="blogCard__title text-white">
                    THE DIFFERENT TYPES OF<br />
                    LUXURY SPACES
                  </h3>
                </div>

                <div class="blogCard__date text-accent-1">
                  <div>17</div>
                  <div>NOV</div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-lg-4 col-md-6">
            <a href="blog-single.html" class="blogCard -type-1 -hover-accent">
              <div class="blogCard__content px-40 py-40">
                <div>
                  <div class="blogCard__tag text-accent-1">NEWS</div>
                  <h3 class="blogCard__title text-white">
                    HOW IS THE CONSTRUCTION OF<br />
                    SKY PARK RESIDENCES GOING?
                  </h3>
                </div>

                <div class="blogCard__date text-accent-1">
                  <div>24</div>
                  <div>NOV</div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-lg-4 col-md-6">
            <a href="blog-single.html" class="blogCard -type-1 -hover-accent">
              <div class="blogCard__content px-40 py-40">
                <div>
                  <div class="blogCard__tag text-accent-1">NEWS</div>
                  <h3 class="blogCard__title text-white">
                    CONSTRUCTION OF SKY PARK<br />
                    RESIDENCES PROCEEDS ACCORDING<br />
                    TO SCHEDULE
                  </h3>
                </div>

                <div class="blogCard__date text-accent-1">
                  <div>12</div>
                  <div>NOV</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="layout-pt-lg layout-pb-lg bg-accent-2">
      <div class="container">
        <div class="row justify-center">
          <div class="col-lg-10">
            <h2 class="text-center text-62 md:text-30">
              <span class="text-accent-1">REQUEST</span>
              A VISIT
            </h2>
          </div>
        </div>

        <div class="row justify-center pt-60 md:pt-30">
          <div class="col-xl-6 col-lg-8 col-md-10">
            <div class="contactForm">
              <div class="contactForm__field">
                <div class="contactForm__caption">Name</div>
                <input type="text" />
              </div>

              <div class="contactForm__field mt-30">
                <div class="contactForm__caption">phone</div>
                <input type="text" />
              </div>

              <div class="contactForm__field mt-30">
                <div class="contactForm__caption">emaıl (optıonal)</div>
                <input type="text" />
              </div>

              <div class="mt-30">
                <button class="button -md -dark-1 bg-accent-1 text-white col-12">SEND REQUEST</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="footer -type-1 bg-dark-2 text-white">
      <div class="footer__top">
        <div class="footer__links">
          <a href="#">HOME</a>
          <a href="#">ABOUT</a>
          <a href="#">LOCATION</a>
          <a href="#">SELECT AN APARTMENT</a>
          <a href="#">GALLERY</a>
          <a href="#">CONTACT US</a>
        </div>

        <div class="footer__socials">
          <a href="#" class="icon-facebook"></a>
          <a href="#" class="icon-instagram"></a>
          <a href="#" class="icon-twitter"></a>
        </div>
      </div>

      <div class="footer__main">
        <div class="footer__icon">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_407_6762)">
              <path
                d="M40.1377 32.9966L49.9377 29.3482L50.0389 99.9978C53.3371 99.9984 56.6273 99.6742 59.8619 99.0299V33L49.9964 27.3042L40.1377 32.9966Z"
                fill="#C29F7B"
              />
              <path
                d="M85.3559 14.6441C75.9115 5.2021 63.3563 0 50 0C36.6437 0 24.0885 5.2021 14.6441 14.6441C5.1998 24.0862 0 36.6449 0 50C0 63.3551 5.2021 75.9115 14.6441 85.3559C19.1043 89.8295 24.3722 93.4169 30.1687 95.9281C33.3344 97.2961 36.6349 98.3284 40.0159 99.0079V47.308L30.1538 41.6122L20.2916 47.3023L30.0939 43.655L30.1538 86.0395C17.4616 79.019 8.84932 65.4993 8.84932 50C8.84932 27.3099 27.3099 8.84932 50 8.84932C72.6901 8.84932 91.1518 27.3099 91.1518 50C91.1589 60.6043 87.0578 70.7991 79.7095 78.4447V47.308L69.8439 41.6122L59.9841 47.3023L69.7841 43.655L69.86 95.9177C73.35 94.406 76.6544 92.4979 79.7084 90.2311C81.7078 88.7462 83.5954 87.1163 85.3559 85.3547C94.8002 75.9104 100 63.3551 100 49.9988C100 36.6426 94.8002 24.0885 85.3559 14.6441Z"
                fill="#C29F7B"
              />
            </g>
            <defs>
              <clipPath id="clip0_407_6762">
                <rect width="100" height="100" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div class="footer__number">+25 968 38 70 00</div>
        <div class="footer__mainContent">
          <h4>SALES OFFICE</h4>
          <p>
            329 Queensberry Street, North Melbourne <br />
            VIC3051, Australia.<br />
            Opening hours: 9:00 — 21:00
          </p>
        </div>
      </div>

      <div class="container">
        <div class="footer__bottom">
          <div>© 2023 NERF. All Right Reserved.</div>
          <div class="md:d-none">Website by CreativeLayers</div>
          <div>
            <a href="#">Privacy policy</a>
            <a href="#">Cookies</a>
            <a href="#">Documents</a>
          </div>
        </div>
      </div>
    </footer>

    <div class="requestForm js-requestForm">
      <div class="requestForm__overlay js-toggle-requestForm"></div>

      <div class="requestForm__content">
        <div class="requestForm__container">
          <div class="requestForm__close js-toggle-requestForm">
            <button>
              <i class="icon-cross"></i>
            </button>
          </div>

          <h3 class="requestForm__title">
            <span class="text-accent-1">Request</span><br />
            a visit
          </h3>

          <div class="requestForm__form contactForm row y-gap-30">
            <div class="col-12">
              <div class="contactForm__field">
                <div class="lh-12 fw-500 uppercase">Full name</div>
                <input type="text" />
              </div>
            </div>
            <div class="col-12">
              <div class="contactForm__field">
                <div class="lh-12 fw-500 uppercase">phone</div>
                <input type="text" />
              </div>
            </div>
            <div class="col-12">
              <div class="contactForm__field">
                <div class="lh-12 fw-500 uppercase">E-MAIL</div>
                <input type="text" />
              </div>
            </div>

            <div class="col-sm-6">
              <div class="contactForm__field">
                <div class="lh-12 fw-500 uppercase">DATE</div>
                <input type="text" />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="contactForm__field">
                <div class="lh-12 fw-500 uppercase">TIME</div>
                <input type="text" />
              </div>
            </div>

            <div class="col-12">
              <div class="contactForm__field">
                <div class="lh-12 fw-500 uppercase">YOUR MESSAGE</div>
                <textarea rows="3"></textarea>
              </div>
            </div>
            <div class="col-12">
              <button class="button -md -dark-1 bg-accent-1 text-white col-12">submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
-->
  <h1>test</h1>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme7Test',
  mixins: [ComponentMixin],
});
</script>
