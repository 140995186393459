<template>
  <h1>Property Detail</h1>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { ROUTES } from '~/constants/ui.constants';
import { usePropertiesStore } from '~/units/properties/stores';
import type { Component } from '~/types';
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertyMixin } from '~/units/properties/mixins/property.mixin';
import FloorPlan from '~/components/common/FloorPlan.vue';
import NeuronGoogleMap from '~/components/common/NeuronGoogleMap.vue';
import Epc from '~/components/common/Epc.vue';
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import NcInput from '~/components/common/inputs/NcInput.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import FullScreenGallery from '~/components/common/FullScreenGallery.vue';
import { useCoreStore } from '~/units/core/store';
import SendLead from '~/components/common/leads/SendLead.vue';
import Success from '~/components/common/popup/Success.vue';
import Popup from '~/components/common/popup/Popup.vue';
import PropertyDetailActions from '~/components/theme4/properties/PropertyDetailActions.vue';
import AvailableComponentsForSidebar from '~/components/theme4/AvailableComponentsForSidebar.vue';
import { FullScreenPopupMixin } from '~/units/core/mixins/fullScreenPopupMixin';
import { getImagesByCount } from '~/units/properties/store';

export default defineNuxtComponent({
  name: 'Theme7PropertyDetail',
  components: {
    AvailableComponentsForSidebar,
    PropertyDetailActions,
    Popup,
    Success,
    SendLead,
    FullScreenGallery,
    NcButton,
    NcInput,
    NcSelect,
    Epc,
    NeuronGoogleMap,
    FloorPlan,
  },

  mixins: [ComponentMixin, PropertyMixin, FullScreenPopupMixin],

  data() {
    return {
      activeTab: { name: 'features', title: 'FEATURES', is_visible: !!this.featuredVideo },
      activeTabV2: { name: 'features', title: 'FEATURES' },
      swiper: {},
      isScrolled: false,
      scrollThreshold: 0,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      videoPopupConfig: {
        isActive: false,
        videoPopupSrc: this.featuredVideo,
      },
    };
  },

  mounted() {
    this.setScrollThreshold();
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },

  computed: {
    tabs() {
      const tabs = [
        {
          name: 'features',
          title: 'FEATURES',
          is_visible: !!this.property?.features,
        },
        {
          name: 'property_information',
          title: 'PROPERTY INFO',
          is_visible: true,
        },
        {
          name: 'floor_plan',
          title: 'FLOOR PLANS',
          is_visible: !!this.property?.floor_plans?.length,
        },
        {
          name: 'map',
          title: 'MAP',
          is_visible: !!this.property.map_position,
        },
        {
          name: 'epc',
          title: 'EPC',
          is_visible: !!this.property?.epcs?.length,
        },
        {
          name: 'matterport_video',
          title: 'MATTERPORT VIDEO',
          is_visible: !!this.matterPort,
        },
        {
          name: 'video_tour',
          title: 'VIDEO TOUR',
          is_visible: !!this.featuredVideo,
        },
      ];

      return tabs.filter((i) => i.is_visible);
    },

    tabsV2() {
      const tabs = [
        {
          name: 'cinematic_videography',
          title: 'CINEMATIC VIDEO',
          is_visible: !!this.featuredVideo,
        },
        {
          name: 'features',
          title: 'FEATURES',
          is_visible: !!this.property?.features,
        },
        {
          name: 'property_information',
          title: 'PROPERTY INFO',
          is_visible: true,
        },
        {
          name: 'floor_plan',
          title: 'FLOOR PLANS',
          is_visible: !!this.property?.floor_plans?.length,
        },
        {
          name: 'map',
          title: 'MAP',
          is_visible: !!this.property.map_position,
        },
        {
          name: 'epc',
          title: 'EPC',
          is_visible: !!this.property?.epcs?.length,
        },
        /*         {
          name: 'matterport_video',
          title: 'MATTERPORT VIDEO',
          is_visible: !!this.matterPort,
        }, */
      ];

      return tabs.filter((i) => i.is_visible);
    },

    markerColor() {
      return this.colors.primary;
    },

    ROUTES() {
      return ROUTES;
    },

    primaryFivePhotos() {
      if (this.property) {
        return getImagesByCount(this.property, 5);
      }
      return [];
    },

    primarySevenPhotos() {
      if (this.property) {
        return getImagesByCount(this.property, 7);
      }
      return [];
    },

    sidebarComponents(): Component[] {
      if (this.currentPage && this.currentPage.sidebar) {
        return this.currentPage.sidebar;
      }
      return [];
    },

    isMortgageCalculatorVisible() {
      return !this.vars.isMortgageCalculatorHiddenNumber;
    },

    isStampDutyCalculatorVisible() {
      return !this.vars.isStampDutyCalculatorHiddenNumber;
    },

    isLetterPropertyCriteria() {
      return this.vars.isLetterPropertyCriteriaNumber;
    },

    featuredVideo() {
      const mediaLink = this.property.featured_video?.mediaLink;
      if (mediaLink && mediaLink.includes('matterport')) {
        return null;
      }
      return mediaLink;
    },

    matterPort() {
      let mediaLink = this.property?.virtual_tour?.mediaLink;
      if (mediaLink && mediaLink.includes('youtu')) {
        return null;
      }
      if (mediaLink && mediaLink.startsWith('http://')) {
        mediaLink = mediaLink.replace('http://', 'https://');
      }
      return mediaLink;
    },
  },

  methods: {
    ...mapActions(useCoreStore, ['shareApi', 'openTab']),
    async onShared() {
      await this.shareApi({
        title: this.property?.advert_heading,
        text: this.property?.advert_heading,
        url: window.location.href,
      });
    },

    onSwiper(swiper: object) {
      this.swiper = swiper;
    },

    href(location) {
      this.activeTab.name = location;
    },

    handleScroll() {
      this.isScrolled = window.scrollY > this.scrollThreshold;
    },

    setScrollThreshold() {
      nextTick(() => {
        const ulElement = this.$refs.navTabs;
        if (ulElement) {
          this.scrollThreshold = ulElement.offsetTop - 90;
        }
      });
    },

    onVidePopup() {
      this.videoPopupConfig.isActive = !this.videoPopupConfig.isActive;
      this.videoPopupConfig.videoPopupSrc = this.featuredVideo;
    },

    stopVideo() {
      this.videoPopupConfig.videoPopupSrc = '';
    },
  },

  async setup(props: { component: Component }) {
    const store = usePropertiesStore();
    await store.fetchSingleProperty(props.component);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
});
</script>

<style scoped>
.share-button {
  border: 2px solid var(--camel);
}

.nav-link.active {
  color: var(--purple);
  border-bottom-color: var(--purple);
}

.nav-link {
  color: var(--black);
  font-size: 16px;
}

.nc-first-image-overlay-item {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  transform: translate(-50%, -50%);
  text-align: right;
  background-color: var(--white);
  color: var(--nc-classic-black);
  border-radius: 4px;
}

.nc-all-photos-button {
  position: absolute;
  bottom: 20px;
  left: 70px;
  padding: 10px;
  border-radius: 4px;
  background-color: var(--white);
  color: var(--nc-classic-black);
}

.photo-next,
.photo-prev {
  position: absolute;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 44px;
  height: 64px;
  background-color: #fff;
  border-radius: var(--rounded);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  font-size: 26px;
}

.photo-next {
  right: 5%;
}

.photo-prev {
  left: 5%;
}

::v-deep(.floor-tab .floor-plan-items .floor-plan-card) {
  width: 100% !important;
}

::v-deep(.floor-tab .floor-plan-items .floor-plan-card figure) {
  border: none !important;
}

::v-deep(.floor-tab) {
  padding: unset !important;
}

::v-deep(.inline-iframe-container iframe) {
  height: 590px;
}

.theme4-floor-plan ::v-deep(figure) {
  height: unset !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: unset;
}
.swiper-button-next,
.swiper-button-prev {
  width: unset;
  height: unset;
}

::v-deep(img) {
  border-radius: 4px;
}

.property-detail-card {
  padding: 24px;
  border-radius: var(--rounded);
  background-color: var(--c-section_primary);
}

.property-detail-card.accordion {
  padding-bottom: 0;
  padding-top: 0;
}

@media screen and (min-width: 1024px) {
  .property-detail-card {
    padding: 32px;
  }

  .property-detail-card.accordion {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.accordion-stamp-duty-calculator ::v-deep(h3),
.accordion-mortgage-calculator ::v-deep(h3) {
  display: none;
}

.accordion-stamp-duty-calculator ::v-deep(.stamp-duty-result),
.accordion-mortgage-calculator ::v-deep(.mortgage-result) {
  background: var(--c-primary);
  border-radius: var(--rounded);
}
.accordion-stamp-duty-calculator ::v-deep(select),
.accordion-stamp-duty-calculator ::v-deep(.n-input),
.accordion-stamp-duty-calculator ::v-deep(input),
.accordion-mortgage-calculator ::v-deep(select),
.accordion-mortgage-calculator ::v-deep(.n-input),
.accordion-mortgage-calculator ::v-deep(input) {
  background: var(--c-input_secondary) !important;
}

.nav-link:hover {
  color: var(--c-primary);
}

.active {
  color: var(--c-primary) !important;
}
</style>
