<template>
  <h1>Our Blog</h1>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import type { Component } from '~/types';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import { useWebsiteStore } from '~/units/website/store';
import { ROUTES } from '~/constants/ui.constants';
import Loader from '~/components/common/Loader.vue';
export default defineNuxtComponent({
  name: 'Theme7OurBlog',

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  components: {
    Loader,
  },

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,

      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
    ROUTES() {
      return ROUTES;
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
  mounted() {
    this.onSlideChange();
  },
});
</script>
