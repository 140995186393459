<template>
  <section class="layout-pt-lg" :style="`background-color: var(--c-${vars.backgroundColor});`">
    <div class="marquee js-marquee">
      <div class="js-first">{{ vars.firstText }}</div>
      <div class="js-second text-accent-1">{{ vars.secondText }}</div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'Theme7ScrollAnimationTexts',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
      },
    };
  },
});
</script>
